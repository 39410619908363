html {
    --color-text: black;
    --color-light-bg: #f3f3f3;
    --color-border: #bdbdbd;
    --color-border-light: #d3d3d3;
    --color-border-dark: #2f2f2f;
    --color-primary: #4f50d5;
    --color-primary-wop: rgba(78, 78, 212, 0.06);
    --color-error: #ff0000;
    --color-font-secondary: #505050;
    --color-font-heading: #000000;
    --color-font-subheading: #000000;
    --color-font-body: #000000;
    --color-font-about-section: #000000;
    --color-sidebar-body: #000000;
    --color-sidebar-heading: #000000;
    --gutter: 16px;
    --body-font-family: 'Roboto';
    --name-font-size: 36px;
    --name-font-case: 'capitalize';
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

body {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

.item-disabled {
    cursor: wait;
    pointer-events: none;
}

/* ====================
   RESUME SECTION START 
   ==================== */

.resume-section {
    padding: 5px;
    margin-bottom: 5px;
}

#resumPaperContainer .resume-section-wrap:hover {
    outline: 1px dashed rgb(90, 89, 89);
    border-radius: 4px;
    /* background-color: rgb(250, 250, 250); */
}

.component-dragging {
    outline: 1px dashed rgb(90, 89, 89);
    border-radius: 4px;
    background-color: #ffffff;
}

.resume-section-title {
    display: flex;
    margin: 0 0 10px 0;
    font-size: 20px;
    font-weight: 500;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.component-library .resume-section-title {
    border-bottom: 2px solid #000000;
}

.section-meta {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
}

.section-meta-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 2px;
    min-width: 80px;
}

.section-meta-icon {
    padding-right: 6px;
}

.heading-font-x-small .resume-section-title,
.subheading-font-x-small .resume-section-subtitle,
.body-font-x-small .resume-section-body {
    font-size: x-small;
}

.heading-font-small .resume-section-title,
.subheading-font-small .resume-section-subtitle,
.body-font-small .resume-section-body {
    font-size: small;
}

.heading-font-medium .resume-section-title,
.subheading-font-medium .resume-section-subtitle,
.body-font-medium .resume-section-body {
    font-size: 15px;
}

.heading-font-large .resume-section-title,
.subheading-font-large .resume-section-subtitle,
.body-font-large .resume-section-body {
    font-size: large;
}

.heading-font-larger .resume-section-title,
.subheading-font-larger .resume-section-subtitle,
.body-font-larger .resume-section-body {
    font-size: 20px;
}

.resume-paper.heading-alignment-left .resume-section-title {
    justify-content: left;
}

.resume-paper.heading-alignment-center .resume-section-title {
    justify-content: center;
}

.resume-paper.heading-alignment-right .resume-section-title {
    justify-content: right;
}

#header .resume-section-subtitle {
    color: var(--color-font-about-section);
}

.resume-paper #main .resume-section-subtitle {
    /* color: var(--color-font-subheading); */
    color: var(--color-font-body) !important;
}

.resume-paper #main .resume-section-body {
    color: var(--color-font-body) !important;
    font-family: var(--body-font-family) !important;
}

.resume-paper #sidebar .resume-section-body {
    color: var(--color-sidebar-body) !important;
    font-family: var(--body-font-family) !important;
}

.resume-paper #main .resume-section-piechart svg text {
    fill: var(--color-font-body) !important;
}

.resume-paper #sidebar .resume-section-piechart svg text {
    fill: var(--color-sidebar-body) !important;
}

.heading-style-with-underline #header .resume-section-title {
    color: var(--color-font-about-section);
    border-bottom: 2px solid var(--color-font-about-section);
}

.heading-style-with-double-line #header .resume-section-title {
    color: var(--color-font-about-section);
    border-bottom: 2px solid var(--color-font-about-section);
    border-top: 2px solid var(--color-font-about-section);
}

.heading-style-with-background #header .resume-section-title {
    color: var(--color-font-about-section);
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.heading-style-with-background #header .resume-section-title::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: var(--color-font-about-section);
    opacity: 0.06;
    border-radius: 4px;
}

.resume-paper.heading-style-with-none #main .resume-section-title,  .resume-paper.heading-style-with-none #pageTwo .resume-section-title {
    color: var(--color-font-heading);
    border: none;
}

.resume-paper.heading-style-with-none #sidebar .resume-section-title {
    color: var(--color-sidebar-heading);
    border: none;
}

.resume-paper.heading-style-with-underline #main .resume-section-title, .resume-paper.heading-style-with-underline #pageTwo .resume-section-title {
    color: var(--color-font-heading);
    border-bottom: 2px solid var(--color-font-heading);
}

.resume-paper.heading-style-with-underline #sidebar .resume-section-title {
    color: var(--color-sidebar-heading);
    border-bottom: 2px solid var(--color-sidebar-heading);
}

.resume-paper.heading-style-with-double-line #main .resume-section-title, .resume-paper.heading-style-with-double-line #pageTwo .resume-section-title {
    color: var(--color-font-heading);
    border-bottom: 2px solid var(--color-font-heading);
    border-top: 2px solid var(--color-font-heading);
}

.resume-paper.heading-style-with-double-line #sidebar .resume-section-title {
    color: var(--color-sidebar-heading);
    border-bottom: 2px solid var(--color-sidebar-heading) !important;
    border-top: 2px solid var(--color-sidebar-heading);
}

.resume-paper.heading-style-with-background #main .resume-section-title, .resume-paper.heading-style-with-background #pageTwo .resume-section-title {
    color: var(--color-font-heading);
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.resume-paper.heading-style-with-background #sidebar .resume-section-title {
    color: var(--color-sidebar-heading);
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.resume-paper.heading-style-with-background #main .resume-section-title::before, .resume-paper.heading-style-with-background #pageTwo .resume-section-title::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: var(--color-font-heading);
    opacity: 0.06;
    border-radius: 4px;
}

.resume-paper.heading-style-with-background #sidebar .resume-section-title::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: var(--color-sidebar-heading);
    opacity: 0.06;
    border-radius: 4px;
}

.resume-name {
    font-size: var(--name-font-size) !important;
    text-transform: var(--name-font-case) !important;
}

.body-font-family {
    font-family: var(--body-font-family) !important;
}

.resume-paper .resume-section-devider .resume-section-title {
    display: none;
}

.no-padding {
    padding: 0 !important;
}

.padding {
    padding-top: 20px !important;
}

#sidebar.padding {
    padding-top: 25px !important;
}

#sidebar .MuiGrid-grid-xs-4 {
    max-width: 50%;
    flex-basis: 50%;
}

#sidebar .MuiGrid-grid-xs-6,
.MuiGrid-grid-xs-8,
.MuiGrid-grid-xs-12,
.MuiGrid-grid-md-6,
.MuiGrid-grid-md-8,
.MuiGrid-grid-md-12 {
    max-width: 100%;
    flex-basis: 100%;
}

.resume-section-wrap:hover .edit-component-icon {
    display: block;
}

.full-width-field {
    width: 100%;
    display: block;
}

.resume-section-wrap {
    position: relative;
}

.resume-paper #header {
    padding: 15px 40px 5px 40px;
    flex: 0;
}

.resume-paper #main {
    padding: 10px 10px 10px 40px;
    height: 100%;
}

.resume-paper #main.main-single-column {
    padding: 10px 40px 10px 40px;
}

.resume-paper #sidebar {
    padding: 10px 40px 10px 10px;
    height: auto;
}

.sidebar-left #main {
    padding: 10px 40px 10px 10px;
}

.sidebar-left #sidebar {
    padding: 10px 10px 10px 40px;
}

#pageTwo .resume-paper-content {
    padding: 20px 40px 20px 40px;
}

/* #header {
  z-index: 1;
} */

.edit-component-icon {
    display: none;
    cursor: pointer;
    color: var(--color-primary);
    width: 20px;
    height: 20px;
    margin-right: 8px;
    z-index: 99;
}

.edit-component-icon svg {
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    padding: 2px;
}

.drag-handle {
    display: none;
    cursor: pointer;
    color: var(--color-primary);
    width: 20px;
    height: 20px;
    margin-right: 8px;
    z-index: 99;
}

.drag-handle svg {
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    padding: 2px;
}

.copy-component {
    display: none;
    cursor: pointer;
    color: var(--color-primary);
    width: 20px;
    height: 20px;
    margin-right: 8px;
    z-index: 99;
}

.copy-component svg {
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    padding: 2px;
}

.remove-component {
    display: none;
    cursor: pointer;
    color: var(--color-primary);
    width: 20px;
    height: 20px;
    margin-right: 8px;
    z-index: 99;
}

.remove-component svg {
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    padding: 2px;
}

.delete-component {
    display: none;
    cursor: pointer;
    color: var(--color-error);
    width: 20px;
    height: 20px;
    margin-right: 8px;
    z-index: 99;
}

.delete-component svg {
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-error);;
    border-radius: 50%;
    padding: 2px;
}

.resume-section-wrap:hover .drag-handle,
.component-dragging .drag-handle,
.resume-section-wrap:hover .copy-component,
.resume-section-wrap:hover .delete-component,
.resume-section-wrap:hover .remove-component {
    display: block;
}

.resume-paper-content-draggin-over {
    border: 1px dashed var(--color-primary);
    background-color: #f7fffc94;
    border-radius: 4px;
    min-height: 200px;
}

/* ====================
   RESUME SECTION END 
==================== */

/* ====================
EDITOR SECTION START 
==================== */
.editor-heading-wrap {
    margin-bottom: 20px;
}

.editor-options-wrap {
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
}

.MuiSvgIcon-root.add-item-icon {
    color: var(--color-primary);
    cursor: pointer;
    margin-right: 8px;
}

.MuiSvgIcon-root.delete-item-icon {
    color: var(--color-error);
    cursor: pointer;
    
}

.editor-item {
    display: flex;
    align-items: center;
}

.items-add-remove {
    min-width: 60px;
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
}

.MuiTimelineConnector-root {
    width: 1px !important;
}

/* ====================
  EDITOR SECTION END 
==================== */

/* =============================
COMPONENT LIBRARY SECTION START 
=============================== */

.component-library-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
}

.component-library-title {
    font-size: 20px;
    font-weight: 700;
}

/* =============================
COMPONENT LIBRARY SECTION END 
=============================== */

.github-picker {
    box-shadow: none !important;
}

.d-none {
    display: none !important;
}

/* =============================
RICH TEXT EDITOR SECTION START
=============================== */
.rich-text-div ol,
.rich-text-div ul {
    padding-left: 15px;
}

.rich-text-div ul {
    list-style-type: disc;
}

.rich-text-div img {
    width: 100%;
}

.rich-text-div p {
    margin: 0;
}

.rich-text-div a {
    text-decoration: none;
}

.rich-text-div .ql-align-justify {
    text-align: justify;
}

.quill {
    max-width: 380px;
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.border-none {
    border: none;
}

/* =============================
RICH TEXT EDITOR SECTION END 
=============================== */

@media screen and (max-width: 480px) {
    .mobile-d-none {
        display: none !important;
    }
}

@page {
    size: A4;
    scale: 1;
    margin: 0 !important;
    padding: 0 !important;
}

@media print {
    body * {
        visibility: hidden;
    }

    .resume-paper-container * {
        visibility: visible;
    }
    .resume-paper-container .resume-paper {
        width: 820px !important;
        height: 1160px !important;
        box-shadow: none !important;
        print-color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
    }

    .resume-paper-container {
        position: absolute;
        width: 820px !important;
        height: auto;
        min-height: 1130px !important;
        left: 0;
        top: 0;
    }
}

@keyframes shake {
    2% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    4% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    6% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    8% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    10% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    12% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    14% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    16% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    18% {
        transform: translate(0px, 0px) rotate(0.5deg);
    }
    20% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    22% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    24% {
        transform: translate(0px, 0px) rotate(0.5deg);
    }
    26% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    28% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    30% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    32% {
        transform: translate(0px, 0px) rotate(0.5deg);
    }
    34% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    36% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    38% {
        transform: translate(0px, 0px) rotate(0.5deg);
    }
    40% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    42% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    44% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    46% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    48% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    50% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    52% {
        transform: translate(0px, 0px) rotate(0.5deg);
    }
    54% {
        transform: translate(0px, 0px) rotate(0.5deg);
    }
    56% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    58% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    60% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    62% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    64% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    66% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    68% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    70% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    72% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    74% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    76% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    78% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    80% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    82% {
        transform: translate(1px, 0px) rotate(0.5deg);
    }
    84% {
        transform: translate(0px, 0px) rotate(0.5deg);
    }
    86% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    88% {
        transform: translate(0px, 0px) rotate(0.5deg);
    }
    90% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    92% {
        transform: translate(0px, 1px) rotate(0.5deg);
    }
    94% {
        transform: translate(0px, 0px) rotate(0.5deg);
    }
    96% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    98% {
        transform: translate(1px, 1px) rotate(0.5deg);
    }
    0%,
    100% {
        transform: translate(0, 0) rotate(0);
    }
}


.download-mode  {
    padding: 0 !important;
    margin: 0 !important;
    
}

.download-mode .MuiPaper-rounded.resume-paper {
    max-width: 100% !important;
    width: 100% !important;
}


.download-mode .MuiPaper-rounded.resume-paper {
    height: 100vh !important;
    box-shadow: none !important;
}

.download-mode #resumePageSeparator {
    display: none;
}