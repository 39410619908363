.experience-item:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dashed var(--color-border-light);
}

.experience-title {
    font-weight: 500;
}

.experience-company {
    font-weight: 500;
}

.experience-summary {
    /* font-size: 0.80rem; */
}

.MuiTimelineContent-root {
    font-family: inherit !important;
}

.MuiTimeline-root {
    margin-bottom: 0;
}