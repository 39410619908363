.setting-editor-container {
    background-color: #fff;
    padding: 20px;
    overflow-y: auto;
    border-radius: 4px;
    height: auto;
    max-height: 90%;
}
.resume-setting-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 20px;
}

.resume-setting-section {
    margin-bottom: 10px;
    border-bottom: 2px solid var(--color-border-dark);
    padding-bottom: 10px;
}

.resume-setting-section:last-child {
    border-bottom: none;
}

.resume-setting-item {
    margin-bottom: 15px;
    display: inline-block;
    margin-right: 10px;
    min-width: 210px;
    vertical-align: text-top;
}

.resume-setting-heading {
    margin-bottom: 10px;
}

.resume-setting-heading span {
    font-size: 22px;
    font-weight: 700;
}

.resume-setting-item-label {
    color: rgb(43, 43, 43);
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 2px;
}

.resume-setting-item-body {
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;
}

.font-style-div {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(187, 187, 187);
    color: rgb(79, 79, 79);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    min-width: 125px;
    min-height: 28px;
    margin-right: 8px;
    cursor: pointer;
    padding: 0px 5px;
    width: auto;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.font-size-div,
.heading-alignment-div {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(187, 187, 187);
    color: rgb(79, 79, 79);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    min-width: 18px;
    min-height: 28px;
    margin-right: 8px;
    cursor: pointer;
    padding: 0px 5px;
}
.heading-alignment-div .MuiSvgIcon-root {
    width: 18px;
}

.font-size-div.active,
.heading-alignment-div.active,
.heading-style-div.active,
.font-style-div.active {
    background-color: rgb(79 80 213 / 11%);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
}

.heading-style-div.active rect {
    stroke: var(--color-primary) !important;
    fill: #4f50d58a !important;
}

.heading-style-div text {
    stroke: #000000 !important;
    fill: #000000 !important;
}

.heading-style-div svg {
    width: 100%;
    height: 100%;
}

.heading-style-div {
    width: 50px;
    border: 1px solid grey;
    height: 20px;
    padding: 5px 5px 4px 5px;
    margin-right: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.resume-setting-selected-color {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    margin-left: 5px;
    border: 1px solid #000000;
    cursor: pointer;
}
