.education-item:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dashed var(--color-border-light);
}
.education-title {
    font-weight: 500;
}

.education-uni {
    font-weight: 500;
}

.education-university {
    /* font-size: 14px; */
    font-size: 0.9em;
    margin-bottom: 2px;
}

.MuiTimelineContent-root {
    font-family: inherit !important;
}

.MuiTimeline-root {
    margin-bottom: 0;
}