.social-item-wrap {
    display: flex;
    align-items: initial;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.social-item {
    margin-right: 25px;
    margin-bottom: 8px;
    min-width: 80px;
}

.social-platform {
    font-weight: 600;
}

/* .social-username {
    font-size: 13px;
} */
