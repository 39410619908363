/* .achievement-item:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dashed var(--color-border-light);
    
} */

.achievement-item {
    display: flex;
    align-items: baseline;
}

.achievement-title {
    font-weight: 600;
}

.achievement-summary {
    /* font-size: 0.80rem; */
    display: inline-flex;
    padding-left: 5px;
    padding-top: 2px;
}

.achievement-icon .MuiSvgIcon-root {
    font-size: 0.8rem;
}

@media screen and (max-width: 480px) {
    .achievement-item {
        display: flex;
        align-items: flex-start;
    }
}
