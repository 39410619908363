.skills-item:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dashed var(--color-border-light);
}

.skills-title {
    font-weight: 600;
}

.skills-chip {
    margin-right: 10px;
    margin-bottom: 10px;
    height: 26px !important;
}

.skills-chip .MuiChip-label {
    padding-left: 7px;
    padding-right: 7px;
}

.skills-chip.no-rounded {
    border-radius: 0;
}

.MuiChip-filled {
    border: 1px solid var(--color-border);
}

.skills-item-wrap {
    margin-top: 4px;
}