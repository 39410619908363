.global-header {
    border-bottom: 1px solid var(--color-border-light);
}
.header-logo {
    width: 40px;
    height: 40px;
}

.header-logo-text {
    flex-grow: 1;
}
.MuiButton-root.header-login-button {
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none;
}

.header-login-button-wrap {
    display: inline-flex;
    justify-content: center;
    width: 130px;
    vertical-align: middle;
}

.MuiButton-root.header-menu-link {
    padding-left: 20px;
    padding-right: 20px;
    text-transform: none;
    margin-right: 10px;
    text-decoration: none;
}

.MuiButton-root.header-menu-link.active {
    background-color: var(--color-primary-wop);
    border: 1px solid var(--color-primary);
}

.MuiCircularProgress-root.login-loader {
    vertical-align: middle;
}
