.feedback-container {
    padding: 40px;
    height: 300px;
}

.feedback-heading {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.share-title {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
}

.share-button-container {
    display: flex;
    flex-wrap: wrap;
}

.share-button {
    margin-right: 10px;
}