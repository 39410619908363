/* VARIANT_ONE */

.basic-section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.basic-info.name {
    font-size: 36px;
    font-weight: 700;
}

.basic-info.current-role {
    margin: 0;
    font-size: 16px;
}
