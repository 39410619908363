.MuiLink-root.builder-header-menu-link {
    padding-left: 20px;
    text-transform: none;
    margin: 0 10px 0 6px;
    text-decoration: none;
    color: var(--color-font-secondary);
}

.MuiLink-root.builder-header-menu-link.active {
    color: var(--color-primary);
    font-weight: 600;
}

.MuiLink-root.builder-header-menu-link:hover {
    background-color: none !important;
}

.resume-paper-wrap {
    padding: 40px;
    background-color: var(--color-light-bg);
}

#pageOne,
#pageTwo,
#pageThree {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
}

.resume-paper-main-content {
    display: flex;
    height: 100%;
    flex: 1;
    overflow: hidden;
}

.resume-paper-main-content.sidebar-left {
    flex-direction: row-reverse;
}

.MuiPaper-rounded.resume-paper {
    width: 100%;
    max-width: 820px;
    height: 1180px;
    max-height: 1180px;
    /* overflow: hidden; */
    display: flex;
    flex: 0;
}


/* BASIC INFO COMPONENT */
.basic-info.variant-1.name {
    text-transform: uppercase;
}

.grid-item {
    padding: 10px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.grid-item-content {
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    background: #08e;
    display: flex;
    justify-content: center;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    border-radius: 50%;
}

.builder-header {
    justify-content: space-between;
}

.header-filters {
    display: flex;
    align-items: center;
}

.resume-paper-content {
    height: 100%;
}

.layout-options {
    display: flex;
    margin-bottom: 12px;
    width: 100%;
    max-width: 820px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.layout-option-item:not(:last-child) {
    display: flex;
    margin-right: 10px;
}

.layout-option-items {
    display: flex;
}

.layout-option-item .MuiButton-root {
    text-transform: none;
}

.layout-option-item svg {
    width: 18px;
    height: 18px;
}

.layout-options svg {
    cursor: pointer;
}

.overlay {
    width: auto;
    padding: 8px 4px 8px 8px;
    display: none;
    position: absolute;
    top: 0px;
    outline: 1px solid #000;
    border-radius: 4px;
    right: 0;
}

.resume-section-wrap:hover .overlay,
.component-dragging .overlay {
    display: flex;
    background-color: #fff;
    box-shadow: -1px 2px 6px #0000004a;
    align-items: center;
    justify-content: space-between;
}

.component-library-wrap {
    position: sticky;
    right: 40px;
    top: 10px;
    height: 100vh;
}

.component-library  {
    height: 100vh;
    margin-bottom: 100px;
}

.widget-library {
    padding: 20px;
    height: 76vh;
    overflow-y: auto;
}

.component-library .resume-section-wrap {
    margin: 5px 5px 20px 5px;
    outline: 1px dashed rgb(90, 89, 89);
    border-radius: 4px;
}

.setting-editor-container-wrap {
    position: sticky;
    background-color: #ffffff;
    top: 0;
    right: 0px;
    height: 100%;
    padding: 20px !important;
}

.initial-loader {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

#editorPortal {
    background-color: #fff;
    border-radius: 4px;
    height: auto;
    overflow-y: auto;
    max-height: 90%;
}

#editorPortal .editor-wrap {
    padding: 20px;
}

.editor-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 20px;
}


@media screen and (max-width: 768px) {
    .layout-option-items {
        margin-bottom: 10px;
    }
    .resume-paper-wrap:not(.download-mode) .resume-paper-container {
        padding: 60px 10px;
        zoom: 47%;
    }

    .resume-paper-wrap {
        padding: 20px 0;
    }

    .MuiPaper-rounded.resume-paper {
        width: auto;
    }

    .component-library-wrap {
        padding: 20px 10px;
    }
}

@media screen and (max-width: 480px) {
    .MuiPaper-rounded.resume-paper {
        width: auto;
    }
}