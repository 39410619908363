.language-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
    /* font-size: small; */
}

.language-wrapper {
    margin-bottom: 10px;
}

.progress-wrap {
    width: 100px;
    margin-right: 20px;
    margin-left: 20px;
}
